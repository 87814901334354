/* timeline css */
@keyframes fill-color {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }
  
  @keyframes fill-color1 {
    0% {
      height: 0;
    }
    100% {
      height: 50%;
    }
  }
  @keyframes scaleup {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fade {
    0% {
      color: rgba(black, 0.4);
    }
    100% {
      color: rgba(black, 1);
    }
  }
  body {
    margin: 0;
    padding: 0;
  }
  .timeline {
    padding: 0;
    list-style: none;
    margin: 32px;
    overflow: hidden;
    position: relative;
  }
  .details {
    margin-left: 48px;
    min-height: 85px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .list,
  .list-content {
    position: relative;
    width: 100%;
  }
  .active .status-title{
    color: #409139;
  }
  .status-title{
    text-align: left;
    color: #b4b2b2;
  }
  .list-content::before,
  .list-content::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    transition: 0.2s all linear;
    width: 0.714rem;
    height: 0.714rem;
    border-radius: 50%;
    background-color: gray;
    top: 43%;
    z-index: 3;
    margin-left: 0.35rem;
    margin-top: rem(-8px);
  }
  
  .list-content::after {
    z-index: 2;
  }
  .list {
    position: relative;
    width: 100%;
  }
  
  .list.active .list-content:before {
    transform: scale(0);
    width: 21px;
    height: 21px;
    border: 2px solid white;
    background-color: red;
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/2048px-White_check.svg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px 7px;
    margin-left: 0;
    margin-top: -8px;
    animation: scaleup 0.4s forwards;
  }
  .list:before,
  .list:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    transition: 0.2s all linear;
    width: 0.214rem;
    margin-left: 0.6rem;
  }
  .list:before {
    background: #f2f2f2;
    height: 100%;
  }
  .list:after {
    background: red;
    height: 0;
    z-index: 1;
  }
  .list:before {
    top: -50%;
  }
  .list.active:after {
    top: 0;
    animation: fill-color 0.4s forwards;
  }
  .list:last-child:after {
    display: none;
  }
  .list:last-child.active:after {
    display: block;
    bottom: 50%;
    animation: fill-color1 0.4s forwards;
  }
  .list:last-child .details {
    border-bottom: none;
  }
  .list:first-child:before {
    display: none;
  }
  .list:first-child.active:after {
    animation: fill-color1 0.4s forwards;
    top: 50%;
  }
  .list:first-child.active:after {
    animation-delay: 1s;
  }
  .list:first-child.active .list-content:before {
    animation-delay: 0.5s;
  }
  
  .list:nth-child(2).active:after {
    animation-delay: 2s;
  }
  .list:nth-child(2).active .list-content:before {
    animation-delay: 2s;
  }
  
  .list:nth-child(3).active:after {
    animation-delay: 3s;
  }
  .list:nth-child(3).active .list-content:before {
    animation-delay: 3s;
  }
  
  .list:nth-child(4).active:after {
    animation-delay: 4s;
  }
  .list:nth-child(4).active .list-content:before {
    animation-delay: 4.15s;
  }